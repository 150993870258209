.homepage {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.homepage .overlap-wrapper {
  background-color: #ffffff;
  height: 2105px;
  width: 1440px;
}
/* Base styles for elements */
.homepage h1, .homepage p {
  margin: 0;

}

/* Responsive styles */
@media (max-width: 1024px) {
  .homepage {
    font-size: 0.8rem; /* Adjust font size */
  }

  .homepage h1 {
    font-size: 1.5rem;
  }

  .homepage p {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .homepage {
    font-size: 0.6rem; /* Further adjust font size */
  }

  .homepage h1 {
    font-size: 1.2rem;
  }

  .homepage p {
    font-size: 0.8rem;
  }
}
@media (max-width: 1024px) {
  .homepage {
  zoom: .55;
  transform-origin: 0 0;
  -moz-transform: scale(.55);
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
  transform: scale(1);
  }
}




.homepage .div-2 {
  background-color: #ffffff;
  height: 3547px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.homepage .overlap-3 {
  height: 1844px;
  left: 0;
  position: absolute;
  top: 975px;
  width: 100%;
}

.homepage .overlap-4 {
  height: 1844px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.homepage .grey {
  height: 1352px;
  left: 0;
  position: absolute;
  top: 488px;
  width: 100%;
}

.homepage .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 247px;
  left: 110px;
  position: absolute;
  top: 1275px;
  width: 568px;
}

.homepage .text-2 {
  align-self: stretch;
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 38px;
  position: relative;
}

.homepage .text-wrapper-14 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 38px;
}

.homepage .text-wrapper-15 {
  font-weight: 600;
}

.homepage .ATC-s-insights-are {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 40px;
  font-weight: 700;
  height: 72px;
  left: 202px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1772px;
  width: 1035px;
}

.homepage .text-wrapper-16 {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 69px;
  font-weight: 700;
  height: 272px;
  left: 110px;
  letter-spacing: 0;
  line-height: 75px;
  position: absolute;
  top: 1000px;
  width: 605px;
}

.homepage .rectangle-6 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #0000004c;
  height: 400px;
  left: 736px;
  position: absolute;
  top: 440px;
  width: 595px;
}

.homepage .the-aggregate {
  color: transparent;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 764px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 476px;
  width: 540px;
}

.homepage .text-wrapper-17 {
  color: #262227;
  font-weight: 500;
}

.homepage .text-wrapper-18 {
  color: #da1c5c;
  font-weight: 700;
}

.homepage .group-3 {
  height: 21px;
  left: 762px;
  position: absolute;
  top: 806px;
  width: 145px;
}

.homepage .ellipse {
  background-color: #bbbbbb;
  border-radius: 8px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 16px;
}

.homepage .text-wrapper-19 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 200px;
}

.homepage .rectangle-7 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #0000004c;
  height: 840px;
  left: 110px;
  position: absolute;
  top: 0;
  width: 595px;
}

.homepage .the-average-number {
  color: transparent;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 142px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 36px;
  width: 484px;
}

.homepage .group-4 {
  height: 534px;
  left: 155px;
  position: absolute;
  top: 215px;
  width: 512px;
}

.homepage .overlap-5 {
  height: 534px;
  position: relative;
  width: 510px;
}

.homepage .vector-3 {
  width: 139px;
  height: 100px;
  position: absolute;
  top: 59px;
  left: 237px;
}

.homepage .group-5 {
  height: 204px;
  left: 37px;
  position: absolute;
  top: 0;
  width: 206px;
}

.homepage .element-software-wrapper {
  background-color: #bbbbbb;
  border-radius: 101.9px;
  height: 204px;
  position: relative;
  width: 204px;
}

.homepage .element-software {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 37.7px;
  font-weight: 400;
  height: 47px;
  left: 38px;
  letter-spacing: 0;
  line-height: 37.7px;
  position: absolute;
  text-align: center;
  top: 40px;
  width: 128px;
}

.homepage .text-wrapper-20 {
  font-weight: 700;
  font-size: 77px
}

.homepage .text-wrapper-21 {
  font-size: 27.9px;
}

.homepage .text-wrapper-22 {
  font-size: 27.9px;
  line-height: 28px;
}

.homepage .group-6 {
  height: 363px;
  left: 145px;
  position: absolute;
  top: 171px;
  width: 365px;
}

.homepage .overlap-6 {
  background-color: #da1c5c;
  border-radius: 181.36px;
  height: 363px;
  position: relative;
  width: 363px;
}

.homepage .element-software-2 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 45px;
  font-weight: 400;
  height: 103px;
  left: 38px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  text-align: center;
  top: 110px;
  width: 285px;
}

.homepage .text-wrapper-23 {
  font-size: 42.9px;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 20px
}

.homepage .text-wrapper-24 {
  font-size: 42.9px;
  font-weight: 600;
  line-height: 40px;
}

.homepage .text-wrapper-25 {
  color: #262227;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 17px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 101px;
}

.homepage .text-wrapper-26 {
  color: #da1c5c;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 17px;
  left: 72px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 251px;
  white-space: nowrap;
  width: 101px;
}

.homepage .text-wrapper-27 {
  color: #262227;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 13px;
  left: 808px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 624px;
  white-space: nowrap;
  width: 76px;
}

.homepage .text-wrapper-28 {
  color: #da1c5c;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 13px;
  left: 1051px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 624px;
  white-space: nowrap;
  width: 76px;
}

.homepage .element-interrelations {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 55px;
  left: 788px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 693px;
  width: 112px;
}

.homepage .text-wrapper-29 {
  font-size: 16px;
  font-weight: 500;
}

.homepage .element-m-interrelations {
  color: #da1c5c;
  font-family: "Barlow", Helvetica;
  font-size: 40px;
  font-weight: 400;
  height: 55px;
  left: 1031px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 693px;
  width: 125px;
}

.homepage .text-wrapper-30 {
  font-weight: 500;
}

.homepage .group-7 {
  height: 154px;
  left: 1132px;
  position: absolute;
  top: 636px;
  width: 153px;
}

.homepage .overlap-7 {
  height: 154px;
  position: relative;
}

.homepage .group-8 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 66px;
  width: 153px;
}

.homepage .group-9 {
  height: 24px;
  left: 87px;
  position: absolute;
  top: 0;
  width: 66px;
}

.homepage .overlap-group-2 {
  height: 24px;
  position: relative;
}

.homepage .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 43px;
}

.homepage .ellipse-2 {
  background-color: #da1c5c;
  border-radius: 12.16px;
  height: 24px;
  left: 42px;
  position: absolute;
  top: 0;
  width: 24px;
}

.homepage .group-10 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 66px;
}

.homepage .line-2 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 12px;
  transform: rotate(180deg);
  width: 43px;
}

.homepage .group-11 {
  height: 24px;
  left: 1px;
  position: absolute;
  top: 65px;
  transform: rotate(-106.81deg);
  width: 153px;
}

.homepage .line-3 {
  height: 41px;
  left: 15px;
  position: absolute;
  top: -9px;
  transform: rotate(106.81deg);
  width: 13px;
}

.homepage .group-12 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 66px;
}

.homepage .line-4 {
  height: 41px;
  left: 15px;
  position: absolute;
  top: -9px;
  transform: rotate(-73.19deg);
  width: 13px;
}

.homepage .group-13 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 66px;
  transform: rotate(-69.8deg);
  width: 153px;
}

.homepage .line-5 {
  height: 41px;
  left: 14px;
  position: absolute;
  top: -8px;
  transform: rotate(69.8deg);
  width: 16px;
}

.homepage .line-6 {
  height: 41px;
  left: 14px;
  position: absolute;
  top: -8px;
  transform: rotate(-110.2deg);
  width: 16px;
}

.homepage .group-14 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 65px;
  transform: rotate(-34.58deg);
  width: 153px;
}

.homepage .line-7 {
  height: 25px;
  left: 4px;
  position: absolute;
  top: -1px;
  transform: rotate(34.58deg);
  width: 36px;
}

.homepage .line-8 {
  height: 25px;
  left: 4px;
  position: absolute;
  top: -1px;
  transform: rotate(-145.42deg);
  width: 36px;
}

.homepage .group-15 {
  height: 24px;
  left: 1px;
  position: absolute;
  top: 66px;
  transform: rotate(36.09deg);
  width: 153px;
}

.homepage .line-9 {
  height: 26px;
  left: 4px;
  position: absolute;
  top: -1px;
  transform: rotate(-36.09deg);
  width: 35px;
}

.homepage .line-10 {
  height: 26px;
  left: 4px;
  position: absolute;
  top: -1px;
  transform: rotate(143.91deg);
  width: 35px;
}

.homepage .ellipse-3 {
  background-color: #262227;
  border-radius: 12.16px;
  height: 24px;
  left: 65px;
  position: absolute;
  top: 66px;
  width: 24px;
}

.homepage .group-16 {
  height: 103px;
  left: 878px;
  position: absolute;
  top: 662px;
  width: 89px;
}

.homepage .overlap-8 {
  height: 63px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 89px;
}

.homepage .group-17 {
  height: 24px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 66px;
}

.homepage .ellipse-4 {
  background-color: #bbbbbb;
  border-radius: 12.16px;
  height: 24px;
  left: 42px;
  position: absolute;
  top: 0;
  width: 24px;
}

.homepage .group-18 {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 24px;
  transform: rotate(30deg);
  width: 66px;
}

.homepage .line-11 {
  height: 22px;
  left: 3px;
  position: absolute;
  top: 1px;
  transform: rotate(-30deg);
  width: 38px;
}

.homepage .ellipse-5 {
  background-color: #262227;
  border-radius: 12.16px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.homepage .group-19 {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 15px;
  transform: rotate(-30deg);
  width: 66px;
}

.homepage .line-12 {
  height: 22px;
  left: 3px;
  position: absolute;
  top: 1px;
  transform: rotate(30deg);
  width: 38px;
}

.homepage .rectangle-8 {
  background: linear-gradient(180deg, rgb(218, 28, 92) 0%, rgb(243, 112, 58) 98.5%);
  border-radius: 5px 5px 0px 0px;
  height: 18px;
  left: 110px;
  position: absolute;
  top: 0;
  width: 595px;
}

.homepage .rectangle-9 {
  background: linear-gradient(180deg, rgb(243, 112, 58) 35.5%, rgb(252, 194, 64) 100%);
  border-radius: 5px 5px 0px 0px;
  height: 18px;
  left: 736px;
  position: absolute;
  top: 440px;
  width: 595px;
}

.homepage .group-20 {
  height: 624px;
  left: 765px;
  position: absolute;
  top: 950px;
  width: 631px;
}

.homepage .overlap-9 {
  background-image: url(../../../static/img/triangle2-1.svg);
  background-size: 100% 100%;
  height: 624px;
  position: relative;
  width: 625px;
}

.homepage .text-wrapper-31 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  height: 62px;
  left: 423px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 72px;
  width: 136px;
}

.homepage .text-wrapper-32 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  height: 62px;
  left: 369px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 513px;
  width: 190px;
}

.homepage .text-wrapper-33 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  height: 62px;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 369px;
  width: 91px;
}

.homepage .triangle {
  height: 524px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: 87px;
  width: 530px;
}

.homepage .line-13 {
  height: 38px;
  left: 61px;
  position: absolute;
  top: 329px;
  width: 38px;
}

.homepage .line-14 {
  height: 32px;
  left: 421px;
  position: absolute;
  top: 481px;
  width: 24px;
}

.homepage .line-15 {
  height: 27px;
  left: 374px;
  position: absolute;
  top: 105px;
  width: 40px;
}

.homepage .vector-4 {
  height: 17px;
  left: 878px;
  position: absolute;
  top: 617px;
  width: 158px;
}

.homepage .overlap-10 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #0000004c;
  height: 418px;
  left: 736px;
  position: absolute;
  top: 0;
  width: 595px;
}

.homepage .overlap-11 {
  height: 350px;
  left: 28px;
  position: absolute;
  top: 36px;
  width: 517px;
}

.homepage .overlap-12 {
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 517px;
}

.homepage .the-time-for-new {
  color: transparent;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 472px;
}

.homepage .days-to {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 124px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 139px;
  white-space: nowrap;
  width: 291px;
}

.homepage .element-days {
  color: #da1c5c;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 39px;
  left: 219px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 275px;
  width: 107px;
}

.homepage .text-wrapper-34 {
  font-size: 18.9px;
  font-weight: 500;
}

.homepage .text-wrapper-35 {
  font-size: 18px;
  font-weight: 500;
}

.homepage .text-wrapper-36 {
  color: #262227;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 12px;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 214px;
  white-space: nowrap;
  width: 78px;
}

.homepage .text-wrapper-37 {
  color: #da1c5c;
  font-family: "Barlow-MediumItalic", Helvetica;
  font-size: 26px;
  font-style: italic;
  font-weight: 500;
  height: 12px;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 294px;
  white-space: nowrap;
  width: 78px;
}

.homepage .rectangle-10 {
  background-color: #da1c5c;
  height: 122px;
  left: 128px;
  position: absolute;
  top: 240px;
  transform: rotate(90deg);
  width: 60px;
}

.homepage .rectangle-11 {
  background-color: #bbbbbb;
  height: 300px;
  left: 217px;
  position: absolute;
  top: 75px;
  transform: rotate(90deg);
  width: 60px;
}

.homepage .line-16 {
  height: 1px;
  left: 97px;
  position: absolute;
  top: 349px;
  width: 413px;
}

.homepage .line-17 {
  height: 176px;
  left: 97px;
  position: absolute;
  top: 174px;
  width: 1px;
}

.homepage .element-days-2 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 40px;
  left: 401px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 198px;
  width: 101px;
}

.homepage .vector-5 {
  width: 95px;
  height: 63px;
  position: absolute;
  top: 257px;
  left: 349px;
}

.homepage .rectangle-12 {
  background: linear-gradient(180deg, rgb(243, 112, 58) 35.5%, rgb(252, 194, 64) 100%);
  border-radius: 5px 5px 0px 0px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 595px;
}

.homepage .overlap-13 {
  height: 696px;
  left: 0;
  position: absolute;
  top: 2851px;
  width: 100%;
}

.homepage .dots-grey {
  height: 567px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 100%;
}

.homepage .rectangle-13 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 2px 10px #00000038;
  height: 384px;
  left: 110px;
  position: absolute;
  top: 0;
  width: 372px;
}

.homepage .rectangle-14 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 2px 10px #00000038;
  height: 384px;
  left: 534px;
  position: absolute;
  top: 0;
  width: 372px;
}

.homepage .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 214px;
  left: 142px;
  position: absolute;
  top: 24px;
  width: 307px;
}

.homepage .span-wrapper {
  align-self: stretch;
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  text-align: center;
}

.homepage .text-wrapper-38 {
  font-size: 25px;
}

.homepage .line-18 {
  height: 3px;
  left: 142px;
  position: absolute;
  top: 120px;
  width: 307px;
}

.homepage .design-component-instance-node {
  left: 0 !important;
  position: absolute !important;
  top: 614px !important;
}

.homepage .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 214px;
  left: 577px;
  position: absolute;
  top: 24px;
  width: 285px;
}

.homepage .line-19 {
  height: 3px;
  left: 566px;
  position: absolute;
  top: 120px;
  width: 307px;
}

.homepage .rectangle-15 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 2px 10px #00000038;
  height: 384px;
  left: 959px;
  position: absolute;
  top: 0;
  width: 372px;
}

.homepage .flexcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 214px;
  left: 1001px;
  position: absolute;
  top: 24px;
  width: 287px;
}

.homepage .line-20 {
  height: 3px;
  left: 991px;
  position: absolute;
  top: 120px;
  width: 307px;
}

.homepage .overlap-14 {
  height: 932px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 100%;
}

.homepage .rectangle-16 {
  background-color: #000000;
  height: 770px;
  left: 12px;
  position: absolute;
  top: 62px;
  width: 100%;
}

.homepage .dots-3 {
  height: 770px;
  left: 13px;
  position: absolute;
  top: 62px;
  width: 1439px;
}

.homepage .group-21 {
  height: 375px;
  left: 0;
  position: absolute;
  top: 400px;
  width: 1730px;
}

.homepage .wave {
  height: 375px;
  left: 364px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1366px;
}

.homepage .wave-2 {
  height: 375px;
  left: 12px;
  position: absolute;
  top: 0;
  width: 170px;
}

.homepage .wave-3 {
  height: 375px;
  left: 182px;
  position: absolute;
  top: 0;
  width: 182px;
}

.homepage .navigation-instance {
  left: 12px !important;
}

.homepage .text-wrapper-39 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 980px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.homepage .text-wrapper-40 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1100px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.homepage .text-wrapper-41 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1240px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.homepage .text-wrapper-42 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1350px;
  
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.homepage .text-wrapper-43 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 65px;
  font-weight: 700;
  height: 49px;
  left: 122px;
  letter-spacing: 1.3px;
  line-height: normal;
  position: absolute;
  top: 245px;
  white-space: nowrap;
  width: 1128px;
}

.homepage .text-wrapper-44 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 83px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 334px;
  width: 633px;
}

.homepage .rectangle-17 {
  background: linear-gradient(180deg, rgb(218, 28, 92) 0%, rgb(243, 112, 58) 83.65%, rgb(252, 194, 64) 100%);
  border-radius: 10px;
  height: 145px;
  left: 328px;
  position: absolute;
  top: 759px;
  width: 806px;
}

.homepage .text-wrapper-45 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 40px;
  font-weight: 700;
  height: 72px;
  left: 385px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 780px;
  width: 693px;
}




@media (max-width: 1024px) {


.homepage .text-wrapper-42 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1350px;
  
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.homepage .text-wrapper-43 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 45px;
  font-weight: 700;
  height: 49px;
  left: 122px;
  letter-spacing: 1.3px;
  line-height: normal;
  position: absolute;
  top: 245px;
  white-space: nowrap;
  width: 1128px;
}

.homepage .text-wrapper-44 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 83px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 334px;
  width: 633px;
}

.homepage .rectangle-17 {
  background: linear-gradient(180deg, rgb(218, 28, 92) 0%, rgb(243, 112, 58) 83.65%, rgb(252, 194, 64) 100%);
  border-radius: 10px;
  height: 145px;
  left: 328px;
  position: absolute;
  top: 759px;
  width: 806px;
}

.homepage .text-wrapper-45 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 72px;
  left: 385px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 780px;
  width: 693px;
}

.homepage .text-wrapper-46 {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 35px;
  font-weight: 600;
  height: 22px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 181px;
  white-space: nowrap;
  width: 298px;
}

.homepage .text-wrapper-17 {
  color: #262227;
  font-weight: 500;
  font-size: 16px
}

.homepage .text-wrapper-18 {
  color: #da1c5c;
  font-weight: 700;
  font-size: 20px
}

.homepage .text-wrapper-23 {
  font-size:20px;
}

.homepage .text-wrapper-21 {
  font-size: 18px;
}


.homepage .text-wrapper-25 {
  font-size: 16px
}

.homepage .text-wrapper-26 {
  font-size: 16px
}

.homepage .text-wrapper-20 {
  font-size: 32px;



}

.homepage .text-wrapper-36 {
  font-size: 16px
}

.homepage .text-wrapper-37 {
  font-size: 16px
}

.homepage .days-to {
  font-size: 16px;

}

.homepage .text-wrapper-27 {
  font-size: 16px
}

.homepage .text-wrapper-28 {
  font-size: 16px
}

.homepage .text-wrapper-23 {
  font-size: 16px
}

.homepage .vector-5 {
  width: 95px;
  height: 63px;
  position: absolute;
  top: 257px;
  left: 363px;
}


.homepage .element-interrelations {
  
  top: 710px;

}


.homepage .element-m-interrelations {
  
  top: 725px;

}

.homepage .text-wrapper-16 {

  font-size: 40px;

}

.homepage .text-wrapper-14 {
  font-size:20px
}

.homepage .text-wrapper-15 {
  font-size:20px
}

.homepage .text-wrapper-31 {
font-size: 20px;
  
}

.homepage .text-wrapper-32 {
  font-size: 20px;
    
  }


  .homepage .text-wrapper-33 {
    font-size: 20px;
      
    }

    .homepage .ATC-s-insights-are {
      color: #f3703a;
      font-family: "Barlow", Helvetica;
      font-size: 28px;
      font-weight: 700;
      height: 72px;
      left: 202px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      text-align: center;
      top: 1772px;
      width: 1035px;
    }


    .homepage .text-wrapper-38 {

      font-size: 16px;

    }

    .homepage .span-wrapper {
      font-size: 26px
    }

    .homepage .text-wrapper-23 {
      font-size: 20px
    }

    

}
.homepage .polygon {
  height: 29px;
  left: 685px;
  position: absolute;
  top: 903px;
  width: 94px;
}


@media (max-width: 500px) {
.homepage {
  zoom: .263;
  transform-origin: 0 0;
  -moz-transform: scale(.293);
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
  transform: scale(1);
  }


.homepage .text-wrapper-46 {

  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 48px;
  font-weight: 600;
  height: 22px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 181px;
  white-space: nowrap;
  width: 298px;
  
}

.homepage .text-wrapper-43 {

  font-size: 58px;
  
}

.homepage .text-wrapper-44 {

  font-size: 32px;
  width: 693px;
  
}

.homepage .text-wrapper-45 {

font-size: 38px;
  
  
}

.homepage .line-19 {

  top: 110px;
  
}




.homepage .line-18   {

  top: 110px;
  
}

.homepage .line-20   {

  top: 110px;
  
}

.homepage .ATC-s-insights-are {
  font-size:40px
}


.homepage .text-wrapper-23 {
  font-size: 40px;
}
}

.homepage .text-wrapper-46 {

  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 48px;
  font-weight: 600;
  height: 22px;
  left: 122px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 181px;
  white-space: nowrap;
  width: 298px;
}