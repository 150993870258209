.footer {
  background-color: #262227;
  height: 132px;
  position: relative;
  width: 1440px;
}

.footer .element-ATC-analytics {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 30px;
  letter-spacing: -0.21px;
  line-height: normal;
  position: absolute;
  top: 27px;
  width: 485px;
}

.footer .group {
  height: 25px;
  left: 1183px;
  position: absolute;
  top: 28px;
  width: 232px;
}

.footer .privacy-policy-terms-wrapper {
  height: 25px;
  position: relative;
  width: 284px;
}

.footer .privacy-policy-terms {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  left: 0;
  letter-spacing: -0.21px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0;
  width: 282px;
}
