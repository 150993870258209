.products {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.products .overlap-wrapper {
  background-color: #ffffff;
  height: 2105px;
  width: 1440px;
}

.products .overlap {
  height: 2105px;
  position: relative;
}

.products .dots {
  height: 817px;
  left: 434px;
  position: absolute;
  top: 585px;
  width: 1006px;
}

.products .img {
  height: 701px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1363px;
  width: 1099px;
}

.products .rectangle {
  background-color: #000000;
  height: 523px;
  left: 0;
  position: absolute;
  top: 62px;
  width: 1440px;
}

.products .dots-2 {
  height: 533px;
  left: 0;
  position: absolute;
  top: 52px;
  width: 1440px;
}

.products .div {
  background-color: #ffffff;
  border-radius: 5px;
  
  height: 747px;
  left: 746px;
  position: absolute;
  top: 732px;
  width: 588px;
}

.products .text-wrapper {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 24px;
  font-style: italic;
  font-weight: 600;
  height: 62px;
  left: 369px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 512px;
  width: 190px;
}

.products .rectangle-2 {
  background-color: #ffffff;
  height: 747px;
  left: 113px;
  position: absolute;
  top: 732px;
  width: 628px;
}

.products .rectangle-6 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #0000004c;
  height: 247px;
  left: 113px;
  position: absolute;
  top: 1492px;
  width: 1220px;
}

.products .navigation-1 {
  height: 66px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 1440px !important;
}

.products .text-wrapper-2 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1024px;
  letter-spacing: -0.27px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-3 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1109px;
  letter-spacing: -0.27px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-4 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1223px;
  letter-spacing: -0.27px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-5 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1317px;
  letter-spacing: -0.27px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .get-more-from-your {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 65px;
  font-weight: 700;
  height: 49px;
  left: 360px;
  letter-spacing: 1.3px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 235px;
  white-space: nowrap;
  width: 720px;
}

.products .text-wrapper-6 {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 35px;
  font-weight: 600;
  height: 22px;
  left: 571px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 174px;
  white-space: nowrap;
  width: 298px;
}

.products .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 2023px !important;
}

.products .p {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 145px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 780px;
  width: 577px;
}

.products .text-wrapper-7 {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 38px;
  font-weight: 700;
  height: 48px;
  left: 154px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 882px;
  width: 457px;
}

.products .text-wrapper-8 {
  color: #262227;
  font-family: "Barlow", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 787px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 1303px;
  width: 507px;
}

.products .text-wrapper-9 {
  color: #f3703a;
  font-family: "Barlow", Helvetica;
  font-size: 38px;
  font-weight: 700;
  height: 48px;
  left: 787px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 882px;
  width: 535px;
}

.products .overlap-group-wrapper {
  height: 47px;
  left: 539px;
  position: absolute;
  top: 1491px;
  width: 134px;
}

.products .overlap-group {
  background-color: #da1c5c;
  border-radius: 30.5px;
  height: 47px;
  position: relative;
  width: 132px;
}

.products .text-wrapper-10 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 20px;
  font-weight: 600;
  height: 22px;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
  width: 120px;
}

.products .screenshot-fitfinder {
  height: 292px;
  left: 154px;
  object-fit: cover;
  position: absolute;
  top: 967px;
  width: 507px;
}

.products .screenshot {
  width: 500px;
  left: 781px;
  position: absolute;
  top: 780px;
  height: 550px;
  
}

.products .rectangle-3 {
  background: linear-gradient(180deg, rgb(218, 28, 92) 0%, rgb(243, 112, 58) 98.5%);
  border-radius: 5px 5px 0px 0px;
  height: 26px;
  left: 113px;
  position: absolute;
  top: 732px;
  width: 588px;
}

.products .rectangle-4 {
  background: linear-gradient(180deg, rgb(243, 112, 58) 20.5%, rgb(252, 194, 64) 100%);
  border-radius: 5px 5px 0px 0px;
  height: 26px;
  left: 746px;
  position: absolute;
  top: 732px;
  width: 588px;
}

.products .rectangle-5 {
  background: linear-gradient(180deg, rgb(218, 28, 92) 27.5%, rgb(243, 112, 58) 100%);
  border-radius: 10px;
  height: 170px;
  left: 278px;
  position: absolute;
  top: 465px;
  width: 885px;
}

.products .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 227px;
  left: 345px;
  position: absolute;
  top: 498px;
  width: 752px;
}

.products .text {
  align-self: stretch;
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  position: relative;
  text-align:center
}

.products .span {
  font-weight: 700;
  margin:0px auto;
  text-align:center
}

.products .text-wrapper-11 {
  font-size: 30px;
  font-weight: 500;
}

.products .div-wrapper {
  height: 146px;
  left: 504px;
  position: absolute;
  top: 1716px;
  width: 437px;
}

.products .overlap-2 {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 146px;
  position: relative;
  width: 433px;
}

.products .text-wrapper-12 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 28px;
  font-weight: 500;
  height: 27px;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 21px;
  white-space: nowrap;
  width: 364px;
}

.products .text-wrapper-13 {
  color: #ffffff;
  font-family: "Barlow", Helvetica;
  font-size: 36px;
  font-weight: 700;
  height: 29px;
  left: 101px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 59px;
  white-space: nowrap;
  width: 211px;
}

.products .vector {
  height: 15px;
  left: 324px;
  position: absolute;
  top: 67px;
  width: 10px;
}

.products .group-2 {
  height: 47px;
  left: 1172px;
  position: absolute;
  top: 1514px;
  width: 134px;
}

.products .vector-wrapper {
  background-color: #f3703a;
  border-radius: 13px;
  height: 26px;
  left: 631px;
  position: absolute;
  top: 970px;
  width: 26px;
}

.products .vector-2 {
  height: 14px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 14px;
}

.products .img-wrapper {
  background-color: #f3703a;
  border-radius: 13px;
  height: 26px;
  left: 1264px;
  position: absolute;
  top: 960px;
  width: 26px;
}

.bullet-list {
  list-style-type: circle;
  margin: 0;
  padding: 0;
}

.principals-box {
  font-family: "Barlow", Helvetica;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  margin: 20px 10px;
}

.principals-box h2 {
  font-family: "Barlow", Helvetica;
  color: #f37022;
  margin-bottom: 35px;
  font-size:36px;
}

.principals {
  font-family: "Barlow", Helvetica;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.principal {
  font-family: "Barlow", Helvetica;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-linkedin {
  background: url('../../../static/img/linkedin-icon.png') no-repeat center center;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.principal h3 {
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  margin: 5px 0;
}

.principal p {
  font-family: "Barlow", Helvetica;
  font-size: 16px;
  color: #555;
}


@media (max-width: 1024px) {
  .products .overlap-wrapper {
  zoom: .55;
  transform-origin: 0 0;
  -moz-transform: scale(.55);
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  transform: scale(1);
  }

.products .get-more-from-your {
   
   text-align: center;
   font-size: 40px;
   padding-top: 20px


  }

  .products .text {

    font-size: 24px;

  }

  .products .text-wrapper-11 {
    font-size: 16px;
    
  }

  .products .flexcontainer {

    height: 245px;
    left: 345px;
    position: absolute;
    top: 478px;
    width: 752px;
  }

  .products .text-wrapper-7 {
    font-size: 24px;
    
  }
  
  .products .text-wrapper-8 {
    font-size: 18px;
    
  }


  .products .text-wrapper-9 {
    font-size: 22px;
    
  }

  .products .p {
   
    font-size: 18px;

  }
  

.products .text-wrapper-2 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 950px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-3 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1100px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-4 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1210px;

  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}

.products .text-wrapper-5 {
  color: #939598;
  font-family: "Barlow", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  left: 1350px;
  
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
}
  
  
}


@media (max-width: 500px) {
  .products .overlap-wrapper {
  zoom: .293;
  transform-origin: 0 0;
  -moz-transform: scale(.55);
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  transform: scale(1);
  }
}
